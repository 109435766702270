// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { /*--*/ }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { /*--*/ }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { /*--*/ }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { /*--*/ }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { /*--*/ }




// `xs` returns only a ruleset and no media query
// ... { ... }

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { /*--*/ }

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { /*--*/ }

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .page-container.w-100 {
        max-width: calc(100vw - 58px);
    }
}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
    .usersidebar {
        width: 420px !important;
        min-width: 420px !important;
    }
    ::ng-deep.table-view-box .datatable-body {
        height: calc(100vh - 407px) !important;
    }
}


// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .page-container.w-100 {
        max-width: calc(100vw - 58px);
    }
    .usersidebar{
        width: 320px !important;
        min-width: 320px !important;
    }
}


