/* You can add global styles to this file, and also import other style files */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

@import "scss/bootstrap-override.scss";
@import "scss/responsive";
@import "bootstrap/scss/bootstrap.scss";
@import "bootstrap/scss/reboot";
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~ngx-bootstrap/datepicker/bs-datepicker.css';
// @import "primeng/resources/themes/lara-light-blue/theme.css";
// @import "primeng/resources/primeng.css"
