@import "../scss/variables";

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900&display=swap');
@import 'material-symbols';
@import "animate.css/animate.min.css";
$theme-colors: (
  "primary": $primary,
  "success": $success,
  "secondary": $secondary,
  "info": $info,
  "danger": $danger,
  "warning": $warning,
  "light": $light-gray,
  "pink": $pink,
);

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 200,
  'GRAD' 0,
  'opsz' 24
}

.icon-bold{
  font-variation-settings:
  'wght' 400,
}

.fill {
  font-variation-settings: 'FILL' 1, 'wght' 200, 'GRAD' 0, 'opsz' 48;
}

.playfair{
  font-family: "Playfair Display", serif;
}

$body-font-family: 'Nunito Sans', sans-serif;
$font-sans-serif: 'Nunito Sans', sans-serif;
$border-color: $border;
.text-secondery-light{
  color: $secondary-light !important;
}
.text-secondary-200{
  color: $secondary-200 !important;
}
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          600;
$font-weight-semibold:        700;
$font-weight-bold:            800;
/*======== Tabs ===========*/
$nav-link-font-weight:400;
$nav-link-color:$secondary-light;
$nav-tabs-link-active-color:$primary;
$nav-underline-link-active-color:$primary;
.nav-underline .nav-link.active, .nav-underline .show>.nav-link{
  font-weight: 400 !important;
}
/*====== Button ============*/

$btn-white-space: nowrap;
$btn-padding-y: 0.396rem;
$btn-padding-x: 16px;
$btn-padding-y-lg: 12px;
$btn-padding-x-lg: 16px;
$btn-padding-y-sm: 0.334rem;
$btn-padding-x-sm: 0.5rem;
$btn-border-radius:4px;
$btn-border-radius-lg:4px;
$btn-font-size:14px;
$btn-font-size-sm:12px;
$btn-font-size-lg:16px;
$min-contrast-ratio: 2.0 !default;
$btn-active-box-shadow:none;
.btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 3px;
  span{
    font-size: 1rem;
  }
}

.btn-light{
  color: $secondary-light !important;
  &:hover{
    background-color: $light-gray !important;
    border-color: $light-gray !important;
  }
  &:focus{
    background-color: $light-gray !important;
    border-color: $light-gray !important;
  }
  &:focus-visible{
    background-color: $light-gray !important;
    border-color: $light-gray !important;
  }
  &:active{
    background-color: $light-gray !important;
    border-color: $light-gray !important;
  }
  &.show{
    background-color: $light-gray !important;
    border-color: $light-gray !important;
  }
}
.curser-pointer{
  cursor: pointer;
}

$dropdown-color: $secondary-light;
$dropdown-link-color: $secondary-light;
$dropdown-font-size: 14px;
$dropdown-border-color: none;
$dropdown-border-width:0;
$dropdown-box-shadow: 0 3px 6px rgba($black, 0.2);
$enable-shadows: true !default; 
// $box-shadow-inset: inset 0 0px 0px rgba(0, 0, 0, 0.075);
$box-shadow-inset: 0 !important; 
$zindex-dropdown: 1026;
/*====== Form Controls ============*/
$input-padding-y: 0.396rem;
$input-padding-x: 12px;
$input-padding-y-lg: 12px;
$input-padding-x-lg: 16px;
$input-padding-y-sm: 0.334rem;
$input-padding-x-sm: 0.5rem;
$input-border-radius: 4px;
$input-border-radius-lg: 4px;
$input-font-size-lg:16px;
$input-font-size-sm:12px;
$form-label-margin-bottom:2px;
$form-label-color:$secondary-light;
$input-color:$secondary-light;
$input-placeholder-color:rgba($secondary-light, 0.3);
$input-disabled-bg:$light-gray;         
$input-disabled-border-color:$light-gray;
$form-check-label-color: $secondary-light;
$form-label-font-size:14px;
$input-font-size:14px;    


.autoComplete-lg input {
  padding: 16px !important;
}
.form-select-sm{
  background-position: right 4px center !important;
  padding-right: 25px !important;
}
.form-check-input{
  --bs-border-color: #b5b5b5;
}
/*====== Form switches ============*/
$form-switch-width: 32px;
$form-switch-padding-start:.5em;
.form-switch{
  .form-check-input{
    height: 20px;
    box-shadow: none !important;
  }
  &.form-switch-sm{
    .form-check-input{
      background-color: rgba($black, 0.05);
      border: none;
      box-shadow: none;
      &:checked{
        background-color: $light-blue;
        --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23786ce6'/%3e%3c/svg%3e");
      }
    }
  }
}
.cdk-drag-placeholder{
  background-color: $primary;
}

@mixin switch($res: 'sm') {
  $index: 1rem;
  $mainVal: 1rem;

  @if $res == 'md' {
      $index: 2rem;
      $mainVal: 1.5rem;
  } @else if $res == 'lg' {
      $index: 3rem;
      $mainVal: 2rem;
  } @else if $res == 'xl' {
      $index: 4rem;
      $mainVal: 2.5rem;
  }

  
  .form-check-input {
      height: $mainVal;
      width: calc(#{$index} + 0.55rem);
      border-radius: $mainVal * 2;
  }
}

.form-check-input {
  clear: left;
}

// YOU CAN PUT ALL RESOLUTION HERE
// sm - DEFAULT, md, lg, xl

.form-switch.form-switch-sm {
  @include switch();
}

.form-switch.form-switch-md {
  @include switch('md');
}

.form-switch.form-switch-lg {
  @include switch('lg');
}

.form-switch.form-switch-xl {
  @include switch('xl');
}

/*====== Table ============*/
$table-color: $secondary-light;
$table-th-font-weight:500;
$table-font-size:14px;
$table-hover-bg: $light-gray;
$table-border-color: $border;
/*====== Tooltips ============*/
$tooltip-bg: $white;
$tooltip-color: $secondary-light;
$tooltip-opacity:1;
$tooltip-font-size:13px;
.tooltip-inner {
  box-shadow: 0 3px 6px rgba($black, 0.2);
}
/*====== accordion ============*/
$accordion-icon-width: 1rem;
$accordion-button-active-bg: rgba($primary, 0.1);
.accordion-button:not(.collapsed){
  box-shadow: none !important;
}
/*====== headding ============*/

$h1-font-size: 3.5rem;
$h2-font-size: 3rem;
$h3-font-size: 2.5rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.125rem;
$h6-font-size: 1rem;
$h7-font-size: 0.875rem;
h3{
  font-weight: 700 !important;
}
h4{
  font-weight: 500 !important;
}

.fs-14{
  font-size: 0.875rem !important;
}

.fs-7{
  font-size: 0.75rem !important;
}
.fs-8{
  font-size: 0.625rem !important;
}
.fs-22{
  font-size: 1.375rem !important;
}

$heading-color: $secondary;
/*====== pagination ============*/
$pagination-border-width: 0;
$pagination-border-radius: 0;
$pagination-color: $secondary-light;
$pagination-active-color: $primary;
$pagination-active-bg: rgba($primary, 0.1);
$pagination-hover-bg: rgba($primary, 0.1);


.opacity-5{
  opacity: 0.05;
}
.opacity-8{
  opacity: 0.8;
  pointer-events: none;
}
.min-w-0{
  min-width: 0;
}
.mw-0{
  min-width: 0;
}
.text-secondary-light{
  color: $secondary-light !important;
}
.bg-secondarylight{
  background-color: $secondary-light !important;
}
.bg-light-blue{
  background-color: $light-blue !important;
}

body{
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal;
  //overflow: hidden;
}
body,html {
  height: 100%;
  max-height: 100%;
}

@supports (height: 100dvh) {
  body,html {
    height: 100dvh;
  }
}

.page-body{
  height: calc(100% - 51px);
}

img {
  max-width: 100%;
  height: auto;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
transition: background-color 9999s ease-in-out 0s;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(50%);
}
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(50%);
}
.btn:focus{
  box-shadow: none !important;
}
.accordion-button{
  box-shadow: none !important;
}

.cdk-dialog-container{
  position: relative;
  height: 100% !important;
  &:focus-visible{
    outline: 0 !important;
  }
}

.confirmPopup {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  .cdk-dialog-container{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    animation: slideFromTop 300ms ease-out;
    .modal-content{
      background-color: $white;
      min-width: 420px;
      max-width: 535px;
      border-radius: 4px;
      box-shadow: 0 12px 24px rgba($black,0.1);
      .modal-header{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 30px;
        .btn-close{
          position: absolute;
          top: 16px;
          right: 16px;
          font-size: 18px;
        }
      }
      .modal-body{
        padding: 0 30px;
        text-align: center;
      }
      .modal-footer{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 30px;
      }
    }
  }
  &.v-center{
    .cdk-dialog-container{
      align-items: center;
    }
  }
  &.userPopup{
    .cdk-dialog-container{
      .modal-content{
        max-width: 720px;
        min-width: 600px;
        width: 100%;
      }
    }
  }
}

@keyframes popupFromCenter {
  0% {
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 0;
  }
  100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
  }
}

.videoPopup {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  // width: 80%;
  // max-width: 600px;
  // height: auto;
  // background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  // padding: 20px;
  z-index: 1000;
  // opacity: 1; // Make sure it's visible

  animation: popupFromCenter 300ms ease-out;

  .cdk-dialog-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      .modal-content {
          background-color: white;
          min-width: 420px;
          max-width: 535px;
          border-radius: 4px;
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);

          .modal-header {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              padding: 30px;

              .btn-close {
                  position: absolute;
                  top: 35px;
                  right: 32px;
                  font-size: 18px;
              }
          }

          .modal-body {
              // padding: 0 30px;
              text-align: center;
          }
      }
  }
}

@keyframes slideFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.cdk-global-scrollblock{
  body{
    overflow: hidden;
  }
}
.cdk-overlay-container:empty {
  display: none;
}
.cdk-overlay-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: inherit;
  z-index: 1025;
  .cdk-overlay-backdrop {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 999;
    background: rgba($black,0.3);
    display: flex;
    justify-content: flex-end;
  }
  .cdk-global-overlay-wrapper {
    width: auto;
    z-index: 999;
    height: 100%;
    position: relative;
  }
}

.globle-canvas {
  --animate-duration: 0.3s;
    --animate-delay: 0.3s;
    --animate-repeat: 0.3s;
  & novagems-customfield-editorpopup{
    outline: 0;
  }
  & novagems-create-custom-report{
    outline: 0;
  }
  & novagems-edit-custom-report{
    outline: 0;
  }
  & novagems-add-report-field{
    outline: 0 !important;
  }
  & novagems-reporting-checkpoints{
    outline: 0 !important;
  }
  & novagems-create-checkpoint{
    outline: 0 !important;
  }
  & novagems-checklist-editorpopup{
    outline: 0 !important;
  }
  & novagems-checklist{
    outline: 0 !important;
  }

  .offcanvas-header {
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
    height: 60px;
  }

  &.offcanvas-first.createshift {
      width: 600px !important;
      .offcanvas-body {
        max-width: 600px;
    }
  }

  .offcanvas-body {
      max-width: 550px;
      width: 100%;
      padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
      height: calc(100% - 120px);
  }
  &.offcanvas-first{
    background: $white;
    top: 0;
    width: 550px !important;
    height: 100%; 
    box-shadow: -8px 0 18px rgba($black,0.1);
  }
  &.offcanvas-first1{
      background: $white;
      top: 0;
      opacity: 1;
      width: 550px !important;
      height: 100%; 
      box-shadow: -8px 0 18px rgba($black,0.1);
      border-right: 1px solid $border;
  }
  &.offcanvas-second{
      background: $white;
      top: 0;
      width: 550px !important;
      height: 100%;
      .cdk-visually-hidden {
        position: fixed;
        left: 0;
        width: 100%;
        height: 100%;
        // background: rgba($black,0.1);
        z-index: -1;
        pointer-events: none;
      }
  }
  &.offcanvas-details{
    background: $white;
    top: 0;
    width: 1337px !important;
    height: 100%; 
    box-shadow: -8px 0 18px rgba($black,0.1);
  }
  .offcanvas-footer{
      background-color: $light-gray;
      width: 100%;
      padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
      height: 60px;
  }
}

.custom-field-list{
  list-style: none;
  margin: 0;
  padding: 0;
  & > li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border;
      .add-attachment {
          position: relative;
          width: 219px;
          border: 1px solid $border;
          padding: 6px 20px;
          height: 40px;
          display: flex;
          gap: 15px;
          align-items: center;
          border-radius: 4px;
          .add-attachment-photo{
              position: absolute;
              opacity: 0;
              width: 100%;
          }
      }
      &:last-child{
          border: none;
      }
      .drag-list-icon{
          cursor: move;
      }
  }
}

.btn-close:focus{
  outline: 0 !important;
  border: none !important;
  box-shadow: none !important;
}


.toast-container .ngx-toastr {
  background-color: $white !important;
  color: $black !important;
}
.toast-close-button{
  color: $black !important;
  opacity: 1 !important;
  font-size: 25px !important;
}
.toast-progress{
  background-color: $primary !important;
}

// .dropdown-menu{
//   .dropdown-item{
//     display: flex;
//     align-items: center;
//   }
//   li{
//     position: relative;
//     .sub-dropdown-menu{
//       margin: 0;
//       padding: 0.5rem 0;
//       list-style: none;
//       position: absolute;
//       top: 0;
//       left: 100%;
//       background-color: $white;
//       box-shadow: 0 8px 24px rgba($primary, 0.2);
//       display: none;
//     }
//     &:hover{
//       > .sub-dropdown-menu{
//         display: block;
//       }
//     }
//   }
//   &.right{
//     .sub-dropdown-menu{
//       right: 100%;
//       left: inherit;
//     }
//   }
// }

.dropdown-toggle{
  position: relative;
  padding-right: 35px !important;
  &::after{
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    border: none !important;
    position: absolute;
    right: 14px;
  }
}

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
}

bs-datepicker-container, bs-daterangepicker-container {
  z-index: 99999;
}bs-datepicker-container, bs-daterangepicker-container {
  z-index: 99999;
}

.country-phone-input-box{
  .form-control {
      width: 100%;
      padding-left: 115px;

      &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }

      &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }

      &[type=number] {
          -moz-appearance: textfield;
      }
  }
  .dropbtn{
    &::after{
      left: -3px;
      position: relative;
    }
  }
}

.updateplan-wrapper{
  height: 100vh;
  width:100vw;
  background: $white;
}
.tooltips-dropdown{
  margin: 0;
  padding: 5px;
  list-style: none;
  min-width: 100px;
  li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0;
    gap: 8px;
    white-space: nowrap;
    span{
      text-align: right;
    }
  }
}
.theme-green .bs-datepicker-head {
  background-color: #786CE6;
}
.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #786CE6;
}

.custom-search{
  position: relative;
  .form-control{
      padding-right: 40px;
      padding-left: 12px;
  }
  .search-btn{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;
      height: 100%;
      min-width: 40px;
  }
}

.cursor-pointer{
  cursor: pointer;
}
.pagination{
  .page-link{
    width: 25px;
    height: 25px;
    max-width: 25px;
    max-height: 25px;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    border-radius: 4px !important;
  }
}
.arrow-right .dropdown-toggle::after {
  transform: rotate(-90deg);
}
.bs-datepicker{
  box-shadow: 0 3px 6px rgba($black,0.1);
  border-radius: 4px;
  overflow: hidden;
  .bs-datepicker-container{
    padding: 0;
    .bs-datepicker-body{
      border: none;
      table{
        td{
          color: $secondary-light;
        }
        th{
          color: $primary;
        }
      }
    }
  }
}

.btn-close{
  opacity: 1 !important;
}

ng-scrollbar {
  --scrollbar-color: black;               /* scroll bars background color */
  --scrollbar-thumb-color: #e6e6e6 !important;        /* scroll bar color */
  --scrollbar-thumb-hover-color: #786CE6 !important;     /* scroll bar hover color */
  --scrollbar-container-color: green;     /* scroll bars container color */
  // .ng-scrollbar-track-wrapper{
  //   width: 0.25rem !important;
  // }
  // .ng-scrollbar-sticky{
  //   width: 0.25rem !important;
  // }
  // .ng-scroll-content{
  //   padding-right: 0.25rem !important;
  // }
}

.full-screen-modal {
  position: fixed !important;
  z-index: 9999;
  background: #fff;
  width: 100vw;
  top: 0 !important;
  left: 0;
  height: 100vh;
  min-width: 100%;
  max-width: 100%;
  display: block;
  right: 0 !important;
}

/*==================== date picker ===================*/

.custom-calendar{
  .p-datepicker{
    padding: 10px;
    border: none;
    color: $secondary;
    .p-datepicker-header{
      padding: 0;
      .p-datepicker-title {
        line-height: 1rem;
      }
    }
    table{
      td{
        padding: 0.2rem;
        & > span {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          transition: box-shadow 0.2s;
          border: 1px solid transparent;
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 11px;
          &.p-highlight{
            background-color: $primary;
            color: $white;
          }
        }
        &.p-datepicker-today{
          span{
            border: 1px solid $secondary-light;
            // background-color: $white;
          }
        }
      }
      th{
        & > span {
          // width: 1rem;
          // height: 1rem;
          font-size: 11px;
        }
      }
    }
    .p-datepicker-title{
      font-size: 11px;
      & button{
        font-size: 11px;
      }
    }
  }

  .p-monthpicker, .p-yearpicker{
    span {
      font-size: 11px;
    }
  }

  .c-header{
    text-align: center;
    .caleWeekDays{
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 8px;
      .days{
        font-size: 10px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $light-gray;
        cursor: pointer;
        &:hover{
          background-color: $primary;
          color: $white;
        }
        &.active{
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
  .p-datepicker-prev, .p-datepicker-next {
    font-size: 12px !important;
    width: 32px;
    height: 32px;
    .p-icon-wrapper svg {
      width: 12px;
    }
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover{
    color: $primary;
  }

}

.custom-calendar .p-datepicker-panel {
  position: unset !important;
  border: none;
  padding: 0 !important;
}

.p-datepicker-panel{
  z-index: 1050 !important;
}
.p-datepicker-weekday {
  width: 100%;
  text-align: center;
  display: block;
}
:root{
  --p-primary-color:#786ce6 !important;
  // --p-slate-300: #786ce6 !important;;
  --p-datepicker-date-range-selected-background: #786ce6 !important;
  --p-datepicker-date-range-selected-color: #fff !important;
  --p-button-text-primary-hover-background: #f3f2ff !important;
  --p-button-text-primary-active-background: #f3f2ff !important;
  --p-datepicker-date-padding: 0.5rem !important;
  --p-datepicker-date-width: 2.5rem !important;
  --p-datepicker-date-height: 2.5rem !important;
}
/*=========== blue color date picker ==========*/


  .theme-blue .bs-datepicker-body table td span.selected, .theme-blue .bs-datepicker-body table td.selected span, .theme-blue .bs-datepicker-body table td span[class*=select-]:after, .theme-blue .bs-datepicker-body table td[class*=select-] span:after {
    background-color: $info;
  }
  .theme-blue .bs-datepicker-head {
    background-color: $info;
  }
  .theme-blue .bs-datepicker-body table td.week span {
    color: $info;
  }
  .theme-blue .bs-datepicker-body table th {
    color: $info !important;
  }

/*==================== date picker end ===================*/



.multidropdown .dropdown-toggle::after {
  top: 8px;
  transform: rotate(-90deg);
}

// p-calendar td span:not(.p-disabled).p-ripple.p-element {
//   border: 1px solid $border;
// }

.globle-canvas.timesheetPopup{
  width: 1100px !important;
}
.globle-canvas.payrollDetailsPopup{
  width: 600px !important;
}
.globle-canvas.timesheetRequestPopup{
  width: 650px !important;
}

.globle-canvas.addEditBreakPopup{
  width: 650px !important;
}

div#schedule-tab-pane {
  outline: 0;
}

div#myTabContent {
  outline: 0;
  height: calc(100% - 60px);
}

// .ngx-datatable.bootstrap .datatable-footer{
//   background: none !important;
//   color: #fff !important;
//   text-align: center !important;
// }


.btn-sm{
  &.dropdown-toggle{
    padding-right: 25px !important;
    &::after {
      right: 7px;
    }
  }
}
.custom-tooltip-row{
  .tooltip-inner{
      max-width:300px;
  }
}
.page-subheader {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  position: sticky;
  top: 60px;
  z-index: 1019;
}
span.datatable-header-cell-wrapper.float-left.ng-star-inserted {
  width: 100%;
}

.disabled{
  opacity: 0.5;
  pointer-events: none;
}
.hover-btn{
  &:hover{
    background-color: $light-gray;
  }
}


@media (max-width: 1199.98px) {

}

@media (max-width: 1399.98px) {

}

.pac-container{
  box-shadow: 0 4px 8px rgba($black,0.1);
  border-radius: 4px;
  border: none;
  margin-top: 1px;
  margin-right: -1px;
  z-index: 99999 !important;
  .pac-item{
    border: none;
    padding: 2px 16px;
    .pac-item-query{
      color: $secondary-light !important;
      .pac-matched{
        color: $primary !important;
      }
    }d
    span{
      color: $secondary-light;
    }
  }
  &::after{
    display: none;
  }
  .pac-icon{
    background-image: url('../assets/images/location-on.svg');
    background-size: 18px;
  }
}
.remove-number-arrow{
  &::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  & [type=number] {
    -moz-appearance: textfield !important;
  }
}


/* Firefox */



main{
  padding-left: 58px;
  height: 100%;
}
.page-container{
  height: 100%;
}
.sidebar-navigation{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1021;
}

.page-header{
  padding-left: 15px;
}

.offset-body-in{
  main{
    padding-left: 58px;
  }
}

.usersidebar{
  width: 519px;
  min-width: 519px;
}

.p-datepicker{
  z-index: 1026 !important;
}
.loader-spinner{
  justify-content: center;
  display: flex;
  margin-top: 20px;
}

body.offset-body-in::after {
  position: fixed;
  width: 100%;
  height: 100%;
  content: "";
  background: #000;
  left: 0;
  top: 0;
  opacity: 0.5;
  z-index: 1020;
}

.animated-border {
  // border: 2px dashed rgba(0, 123, 255, 0.5);
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  position: relative;
  animation: animated-border 1.5s infinite alternate;
}

@keyframes animated-border {
0% {
  border-color: rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
50% {
  border-color: rgba(0, 123, 255, 1);
  box-shadow: 0 0 15px rgba(0, 123, 255, 1);
}
100% {
  border-color: rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
}

.white-space-pre{
  white-space: pre-line !important;
}