$primary : #786CE6;
$primary-dark : #1f436d;
$secondary: #323A45;
$secondary-light: #8B959E;
$secondary-200: #656F7D;
$secondary-dark: #C39C37;
$success: #50C25F;
$success-dark: #4AB258;
$info: #4693ea;
$white: #fff;
$black: #000;
$light-gray: #F7F8FA;
$light-blue: #F0EFFF;
$light-skyblue: #EBF5FF;
$danger: #FF3838;
$warning:#F4C043;
$border: #dadada;
$border-light: #E6E6E6;
$aqua: #48D0C8;
$orange: #F2845F;
$pink: #FF3366;
$velvetgreen : #3dbfb3;
$dark-bg-subtle:#8B959E;

.nav-pills {
    --bs-nav-pills-link-active-color: #786CE6 !important;
    --bs-nav-pills-link-active-bg: #F0EFFF !important;
    .nav-link{
        background-color: $light-gray;
        color: $secondary-light;
        &.active{
            background-color: $light-blue;
            color: $primary;
        }
    }
}
.btn{
    box-shadow: none !important;
}